import React, { FC, useCallback, useState } from 'react';

import 'gatsby-theme-husky/src/components/Header/HeaderNavigation/HeaderNavigation.scss';
import { NavProps } from 'gatsby-theme-husky/src/components/Header/MobileHeaderNavigation/models';
import NavItem from './NavItem/NavItem';
import './HeaderNavigationExtends.scss';

const HeaderNavigation: FC<NavProps> = ({ allNodeNavigation: { nodes } }) => {
  const [activeMainCategory, setActiveMainCategory] = useState<string | null>(null);

  const handleNavigationItemClick = useCallback(
    (id: string | null) => () => {
      setActiveMainCategory(id === activeMainCategory ? null : id);
    },
    [activeMainCategory]
  );

  return (
    <nav className="main-navigation">
      <ul className="main-navigation__list">
        {nodes.map((node) => (
          <NavItem
            key={node.properties.label}
            navItem={node}
            activeMainCategory={activeMainCategory}
            handleNavigationItemClick={handleNavigationItemClick}
          />
        ))}
      </ul>
    </nav>
  );
};

export default HeaderNavigation;
